import React from 'react';
import { Text } from '@rooser/reef';
import AnimatedSpinner from '../../../../generic/AnimatedSpinner/AnimatedSpinner';
import { container } from './classnames';

const AppLoading: React.FunctionComponent<Record<string, never>> = () => (
  <div className={container}>
    <AnimatedSpinner size="large" />
    <Text variant="heading2" color="blue-600">
      Simplifying fish trading...
    </Text>
  </div>
);

export default AppLoading;
