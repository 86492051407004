import React from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { useAppSelector } from '../../../../core/configStore';
import { selectCurrentUser } from '../../../auth/selectors';
import { selectCompany } from '../../../company/selectors';

const useDatadog = (): void => {
  const user = useAppSelector(selectCurrentUser);
  const company = useAppSelector(selectCompany);
  const datadogLogsEnabled = process.env.DATADOG_LOGS_ENABLED === 'true';
  const datadogRumEnabled = process.env.DATADOG_RUM_ENABLED === 'true';
  const version = `v${process.env.AWS_JOB_ID}`;

  React.useEffect(() => {
    if (datadogLogsEnabled) {
      datadogLogs.init({
        clientToken: process.env.DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.eu',
        service: process.env.DATADOG_SERVICE,
        env: process.env.DATADOG_ENV,
        version,
        forwardErrorsToLogs: true,
        forwardConsoleLogs: 'all',
        sessionSampleRate: Number(process.env.DATADOG_SAMPLING_RATE),
        silentMultipleInit: true
      });
    }
  }, [datadogLogsEnabled, version]);

  React.useEffect(() => {
    if (!!user && !!company && datadogRumEnabled) {
      datadogRum.init({
        allowedTracingUrls: [
          process.env.API_URL,
          process.env.OFFER_SERVICE_URL
        ],
        applicationId: process.env.DATADOG_APPLICATION_ID,
        clientToken: process.env.DATADOG_CLIENT_TOKEN,
        excludedActivityUrls: [
          /^https:\/\/clientstream\.launchdarkly\.com/,
          /^https:\/\/sockjs-eu\.pusher\.com/
        ],
        site: 'datadoghq.eu',
        service: process.env.DATADOG_SERVICE,
        env: process.env.DATADOG_ENV,
        sessionReplaySampleRate: 0,
        sessionSampleRate: Number(process.env.DATADOG_SAMPLING_RATE),
        startSessionReplayRecordingManually: true,
        trackLongTasks: true,
        trackResources: true,
        trackUserInteractions: true,
        defaultPrivacyLevel: 'allow',
        silentMultipleInit: true,
        version
      });

      datadogRum.setUser({
        id: user.id,
        name: `${user.firstname} ${user.lastname}`,
        email: user.email,
        companyId: company.id,
        companyName: company.name
      });
    }
  }, [
    user,
    company,
    datadogRumEnabled,
    version
  ]);
};

export default useDatadog;
