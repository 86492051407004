
export const MARKET_SALES_ADD = 'Market: add new sale';
export const MARKET_TRACK_SEARCH_RESULTS = 'Market: track search results';
export const MARKET_SET_IS_PRODUCT_REQUEST_OPEN =
  'Market: set the open state of the product request modal';
export const MARKET_SET_PRODUCT_REQUEST_DELIVERY_DATE =
  'Market: set the delivery date for the product request modal';
export const MARKET_SET_REAL_TIME_NEW_OFFER_IDS = 'Market: set new offers that come in real-time';
export const SWITCH_LANGUAGE = 'Market: language was switched';
export const MARKET_SET_IS_INITIAL_FETCH = 'Market: set isInitialFetch state';

export const MARKET_SET_CURATED_OFFERS = 'Market: set curated offers';
export const MARKET_ADD_CURATED_OFFER = 'Market: add curated offer id to state';
